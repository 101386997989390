/**
 * Scripts
 */

import bodyClass from '../scripts/_bodyclass'
import disableHover from '../scripts/_disableHover'
import resizeClass from '../scripts/_resizeClass'
// import scrollmonitor from '../scripts/_scrollmonitor'
// import barba from '../scripts/_barba';
// import gridOverlay from '../../../templates/partials/gridOverlay/_script'
import '../scripts/_lazyloading'
// import '../scripts/_fontfaceobserver'
// import privacyIframe from '../scripts/_privacyIframe'
// import detectSectionBg from '../scripts/_detectSectionBg'
// import microModal from '../scripts/_microModal'
// import '../scripts/_pagetransition'
// import '../scripts/_isPinned'
import '../scripts/_smoothScroll'
// import '../scripts/_fitText'
// import isotope from '../scripts/_isotope'
// import magicMouse from '../scripts/_magicMouse'
// import rellax from '../scripts/_rellax'
// import simpleParallax from '../scripts/_simpleParallax'

// Modules Array
const scriptsArray = [
  bodyClass,
  // barba,
  // privacyIframe,
  disableHover,
  resizeClass,
  // scrollmonitor,
  // magicMouse,
  // isotope,
  // detectSectionBg,
  // microModal,
  // rellax,
  // simpleParallax,
  // gridOverlay
]

const scripts = {
  // Scripts
  Scripts() {
    if (scriptsArray.length > 0 && scriptsArray !== undefined) {
      scriptsArray.forEach((script) => {
        script.init()
      })
    }
  },

  init() {
    this.Scripts()
  }
}

export default scripts;
