/**
 * Bundle Scripts
 */

// Polyfills
import 'babel-polyfill';
// import objectFitImages from 'object-fit-images';

// Import Libs
// import './lib/bling';

// CSS Import
// import '../css/app.scss';

// Import Partials
import scripts from './partials/_scripts';
import templates from './partials/_templates';
import modules from './partials/_modules';

const app = {
  init() {
    scripts.init();
    templates.init();
    modules.init();
  },
};

// Init App!
app.init();
