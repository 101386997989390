/**
 * modules
 */
// import input from '../../../templates/atoms/input/_script'
// import textarea from '../../../templates/atoms/textarea/_script'
// import preloader from '../../../templates/atoms/preloader/_script'
import accordion from '../../../templates/organisms/accordion/_script'
// import pageHeader from '../../../templates/organisms/pageHeader/_script'
// import counter from '../../../templates/organisms/counter/_script'
import imageSlider from '../../../templates/organisms/imageSlider/_script'
import imageGallery from '../../../templates/organisms/imageGallery/_script'
// import testimonialSlider from '../../../templates/organisms/testimonialSlider/_script'
// import houseSlider from '../../../templates/organisms/houseSlider/_script'
// import pageCover from '../../../templates/organisms/pageCover/_script'
// import infoBox from '../../../templates/molecules/infoBox/_script'
// import map from '../../../templates/organisms/map/_script'
// import cards from '../../../templates/organisms/cards/_script'
// import navExtended from '../../../templates/molecules/navExtended/_script'
// import modal from '../../../templates/molecules/modal/_script'
// import toggle from '../../../templates/molecules/toggle/_script'
// import overlay from '../../../templates/molecules/overlay/_script'
// import pageLoader from '../../../templates/molecules/pageLoader/_script'
// import pageFooter from '../../../templates/organisms/pageFooter/_script'
// import objectList from '../../../templates/organisms/objectList/_script'
// import contactForm from     '../../../templates/organisms/contactForm/_script';


// SLIDER
// import pageSlider from '../../../templates/organisms/pageSlider/_script'
// import projectSlider from '../../../templates/organisms/projectSlider/_script'
import logoSlider from '../../../templates/organisms/logoSlider/_script'
// import newsSlider from '../../../templates/organisms/newsSlider/_script'
// import machines from '../../../templates/organisms/machines/_script'


// Modules Array
const modulesArray = [
  // preloader,
  // input,
  // textarea,
  imageSlider,
  imageGallery,
  accordion,
  // machines,
  // cards,
  // navExtended,
  // counter,
  // pageLoader,
  // pageHeader,
  // infoBox,
  imageSlider,
  // map,
  // pageSlider,
  // pageFooter,
  // objectList,
  // projectSlider,
  logoSlider,
  // newsSlider,
  // contactForm,
  // houseSlider,
  // pageCover,
  // testimonialSlider,
  // toggle,
  // overlay
]

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0 && modulesArray !== undefined) {
      modulesArray.forEach((module) => {
        module.init()
      })
    }
  },

  init() {
    this.Modules()
  }
}

export default modules
